import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import PostListMasonryResponsive from './post-list-masonry-responsive';
import PostListClassic from './post-list-classic';
import PostListMobile from '../post-list-mobile';
import PostListMobileClassic from '../post-list-mobile-classic';
import withDeviceType from '../../hoc/with-device-type';
import withPermissions from '../../hoc/with-permissions';
import { getLayoutType } from '../../selectors/layout-selectors';
import {
  getAllCategories,
  getUniquePostTypesInAllCategories,
} from '../../../common/selectors/categories-selectors';
import { getIsCategorySelectEnabled } from '../../selectors/app-settings-selectors';
import LoadMore from '../load-more';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import styles from './post-list.scss';

class PostList extends Component {
  getListComponent = () => {
    const { isMobile, layout } = this.props;

    let Component;
    if (isMobile) {
      if (layout === LAYOUT_TYPE_PINBOARD) {
        Component = PostListMobile;
      } else {
        Component = PostListMobileClassic;
      }
    } else {
      if (layout === LAYOUT_TYPE_PINBOARD) {
        Component = PostListMasonryResponsive;
      } else {
        Component = PostListClassic;
      }
    }
    return Component;
  };

  isInfiniteList = () => {
    const { layout, isMobile } = this.props;
    return !isMobile && layout === LAYOUT_TYPE_PINBOARD;
  };

  renderComponent = () => {
    const Component = this.getListComponent();
    const {
      isLoading,
      showLoaderInLoadMore,
      currentPagePosts,
      allPosts,
      page,
      entityCount,
      isLoaded,
      loadMore,
      changePage: handlePageChange,
      selectedCategorySlug,
      isCategorySelectEnabled,
      ...props
    } = this.props;
    const showCategoryLink = !selectedCategorySlug && isCategorySelectEnabled;
    const isOnSecondOrMorePage = Math.ceil(allPosts.length / POSTS_PER_PAGE) >= 2;
    if (this.isInfiniteList()) {
      return (
        <LoadMore
          loadMore={loadMore}
          isLoading={showLoaderInLoadMore}
          showButton={showLoaderInLoadMore || isOnSecondOrMorePage}
          remainingEntities={entityCount - allPosts.length}
        >
          <Component
            posts={allPosts}
            entityCount={entityCount}
            showCategoryLink={showCategoryLink}
            showFilters
            showFollowCategoryAction
            isLoading={isLoading}
            {...props}
          />
        </LoadMore>
      );
    }

    return (
      <div>
        <Component
          page={page}
          entityCount={entityCount}
          onPageChange={handlePageChange}
          isLoading={isLoading}
          isLoaded={isLoaded}
          posts={currentPagePosts}
          showCategoryLink={showCategoryLink}
          showFilters
          showFollowCategoryAction
          {...props}
        />
      </div>
    );
  };

  render() {
    const containerClassName = classNames(styles.container, 'post-list');

    return <div className={containerClassName}>{this.renderComponent()}</div>;
  }
}

PostList.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  changePage: PropTypes.func,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  query: PropTypes.string,
  isMobile: PropTypes.bool,
  layout: PropTypes.number,
  entityCount: PropTypes.number,
  page: PropTypes.number,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  selectedCategorySlug: PropTypes.string,
  isCategorySelectEnabled: PropTypes.bool,
  showLoaderInLoadMore: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
};

PostList.defaultProps = {
  layout: LAYOUT_TYPE_PINBOARD,
};

const mapRuntimeToProps = (state, { category, can }, actions, host) => {
  let showCreatePostAction;
  const canCreatePostInCategory = c =>
    (c.isWriteProtected && can('create-post', 'category', c)) ||
    (!c.isWriteProtected && can('read', 'category', c));

  // Post list without category._id shows all posts from all categories
  if (!category._id) {
    // Check if user can post in any of the categories
    showCreatePostAction = getAllCategories(state).some(canCreatePostInCategory);
  } else {
    showCreatePostAction = category && canCreatePostInCategory(category);
  }

  return {
    showCreatePostAction,
    layout: getLayoutType(state, host.style),
    onLikeClick: actions.incrementPostLikeCount,
    isCategorySelectEnabled: getIsCategorySelectEnabled(state, host.style),
    uniquePostTypesInAllCategories: getUniquePostTypesInAllCategories(state),
  };
};

export default flowRight(
  withDeviceType,
  withPermissions,
  connect(mapRuntimeToProps), // Should stay last
)(PostList);
