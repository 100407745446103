import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Breadcrumbs from '../../components/breadcrumbs';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import { connect } from '../../../common/components/runtime-context';
import { getLocation } from '../../../common/store/location/location-selectors';
import { renderSearchInput } from './render-search-input';
import withExperiment from '../../hoc/with-experiment';
import { EXPERIMENT_FORUM_TABS } from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { InternalPageTabsDesktopHeader } from './index';

const InternalPageDesktopHeader = ({ routes, params, isForumTabsEnabled, ...props }) => {
  if (isForumTabsEnabled) {
    return <InternalPageTabsDesktopHeader params={params} />;
  }

  return (
    <DesktopHeader
      left={<Breadcrumbs routes={routes} params={params} />}
      right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
      {...props}
    />
  );
};

InternalPageDesktopHeader.propTypes = {
  // routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapRuntimeToProps = state => ({
  location: getLocation(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(InternalPageDesktopHeader);
